import React, { useState, useEffect } from "react";
// MUI
import {
  Alert,
  Badge,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

//TIME & DATE
import {
  LocalizationProvider,
  MobileDatePicker,
  PickersDay,
  pickersDayClasses,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

//COMPONENTS
import Confirm from "./Confirm";
import { PendingActionsOutlined } from "@mui/icons-material";
import Loading from "./Loading";

//Constants
import {
  darkBlue,
  boxGrey,
  grey,
  borderGrey,
} from "../../constants/ColourConstants";
import CustomButton from "../../constants/CustomButton";

const style = {
  typographyButton: {
    color: darkBlue,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
  },
  typographyBody: {
    color: grey,
    fontSize: 15,
    fontFamily: "Arial",
    textAlign: "center",
  },
  backgroundBox: {
    m: 3,
    p: 3,
    background: boxGrey,
    borderRadius: "16px",
    borderStyle: "solid",
    borderColor: borderGrey,
    borderWidth: "1px",
  },
};

const today = moment(new Date()).format("YYYY-MM-DD");
const tomorrow = moment(new Date()).add(1, "day").format("YYYY-MM-DD");
const FIVE_MINUTES = 300000;
const WAITLIST_START_TIME = new Date().setHours(6, 0, 0, 0);
const WAITLIST_END_TIME = new Date().setHours(18, 0, 0, 0);

const BookingForm = (props) => {
  const formik = props.formik;
  const suggestionNames = props.suggestionNames;
  const highlightedDays = props.highlightedDays;
  const slots = props.slots;
  const isOnWaitlist = props.activeWaitlist;
  const refreshAppointments = props.refreshAppointments;
  const [loading, setLoading] = useState(false);

  const isDateInHolidayRange = (date) => {
    const year = moment(date).year();
    const holidayDates = [`${year}-12-24`, `${year}-12-25`, `${year}-12-26`];
    return holidayDates.includes(moment(date).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refresh();
    }, FIVE_MINUTES);
    return () => {
      clearInterval(refreshInterval);
    };
  });

  const confirmAppointment = Confirm(
    formik,
    `Book an appointment for ${moment(formik.values.date).format(
      "dddd, MMMM Do YYYY"
    )}${
      formik.values.available.time !== undefined
        ? " at " + formik.values.available.time
        : ""
    }?`,
    `If you miss your appointment without giving at least 2 hours notice, you will be charged a $75 fee.`
  );

  const confirmWaitlist = Confirm(
    formik,
    `Be on waitlist for ${moment(formik.values.date).format(
      "dddd, MMMM Do YYYY"
    )}?`,
    ""
  );
  const refresh = async () => {
    setLoading(true);
    formik.setFieldValue("available", "");
    await refreshAppointments();
    setLoading(false);
  };
  return (
    <>
      <Box sx={style.backgroundBox}>
        <Typography variant="h2" sx={style.typographyButton}>
          Book Appointment
        </Typography>
        <form className="form" onSubmit={formik.handleSubmit}>
          <FormControl fullWidth sx={{ my: 1 }} variant="filled">
            <InputLabel id="reason">Reason</InputLabel>
            <Select
              fullWidth
              required
              id="reason"
              name="reason"
              label="Reason"
              variant="filled"
              value={formik.values.reason}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue("available", "");
              }}
              error={formik.touched.reason && Boolean(formik.errors.reason)}
              autoFocus
            >
              {suggestionNames.map((reason, i) => (
                <MenuItem value={reason} key={i}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              error={formik.touched.reason && Boolean(formik.errors.reason)}
            >
              {formik.touched.reason && formik.errors.reason}
            </FormHelperText>
          </FormControl>
          {formik.values.reason !== "" && (
            <>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={8} sx={{ my: 1 }}>
                  <FormControl fullWidth variant="filled">
                    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                      <MobileDatePicker
                        id="date"
                        name="date"
                        label="Select Appointment Date"
                        inputFormat="MM/DD/YYYY"
                        minDate={today}
                        maxDate={tomorrow}
                        disableHighlightToday
                        value={formik.values.date}
                        onChange={(value) => {
                          value !== null &&
                            formik.setFieldValue(
                              "date",
                              moment(value.$d).format("YYYY-MM-DD")
                            );
                        }}
                        inputProps={{ readOnly: true }}
                        renderInput={(params) => (
                          <TextField
                            variant="filled"
                            {...params}
                            fullWidth
                            error={false}
                          />
                        )}
                        renderDay={(day, _value, DayComponentProps) => {
                          const isSelected = highlightedDays.some(
                            (date) =>
                              date.day === day.date() &&
                              date.month === day.month() + 1
                          );
                          return (
                            <Badge
                              key={day.toString()}
                              overlap="circular"
                              invisible={isSelected ? false : true}
                              sx={{
                                "& .MuiBadge-badge": {
                                  backgroundColor: "#005470",
                                },
                              }}
                              variant="dot"
                            >
                              <PickersDay
                                {...DayComponentProps}
                                sx={{
                                  [`&&.${pickersDayClasses.selected}`]: {
                                    backgroundColor: "#005470",
                                  },
                                }}
                              />
                            </Badge>
                          );
                        }}
                      />
                      <FormHelperText
                        error={
                          formik.touched.date && Boolean(formik.errors.date)
                        }
                      >
                        {formik.touched.date && formik.errors.date}
                      </FormHelperText>
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ my: 1 }}>
                  <Tooltip
                    enterTouchDelay={50}
                    title="Appointments are refreshed automatically every five minutes. Please refresh to get the most up-to-date appointments."
                    placement="top"
                    arrow
                  >
                    <span>
                      <CustomButton
                        label="Refresh"
                        disabled={loading}
                        startIcon={<RefreshIcon />}
                        onClick={async () => {
                          await refresh();
                        }}
                      ></CustomButton>
                    </span>
                  </Tooltip>
                  <Loading loading={loading} />
                </Grid>
              </Grid>
            </>
          )}
          {formik.values.date !== "" && slots.length > 0 && (
            <>
              <FormControl fullWidth sx={{ my: 1 }} variant="filled">
                <InputLabel
                  id="available"
                  error={
                    formik.touched.available && Boolean(formik.errors.available)
                  }
                >
                  Select Appointment Time
                </InputLabel>
                <Select
                  fullWidth
                  id="available"
                  name="available"
                  label="Select Time Slot"
                  variant="filled"
                  disabled={formik.values.reason === "" || slots.length === 0}
                  value={formik.values.available}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.available && Boolean(formik.errors.available)
                  }
                >
                  <MenuItem disabled value="waitList"></MenuItem>
                  {slots.length > 0 &&
                    formik.values.reason !== "" &&
                    slots.map((e, i) => (
                      <MenuItem value={e} key={i}>
                        {e.time}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText
                  error={
                    formik.touched.available && Boolean(formik.errors.available)
                  }
                >
                  {formik.touched.available && formik.errors.available}
                </FormHelperText>
              </FormControl>
              {formik.values.available &&
                formik.values.available.time &&
                moment(formik.values.available.time, ["h:mm A"]).format(
                  "HH:mm"
                ) >=
                  (moment(
                    formik.values.available.date,
                    "YYYY-MM-DD"
                  ).isoWeekday() === 1
                    ? "19:00"
                    : "18:15") && (
                  <Alert severity="warning">
                    <strong>
                      X-ray may not be available during or after this time slot.
                      If an X-ray is required, you may be asked to return for
                      this test the following day. Please book accordingly.
                    </strong>
                    <br />
                  </Alert>
                )}
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="noShowFee"
                      name="noShowFee"
                      checked={formik.values.noShowFee}
                      onChange={(e) => {
                        formik.setFieldValue("noShowFee", e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography sx={style.typographyBody}>
                      <strong>No-show fee policy:</strong> I understand that the
                      clinic will charge a no-show fee of $75.00 if I fail to
                      attend my in-person appointment without cancelling at
                      least 2 hours before the scheduled time. I agree to cancel
                      my appointment by calling 431-222-2273 if needed.
                      <br />
                      <strong>
                        All appointments will be in-person at The Minor Illness
                        & Injury Clinic.
                      </strong>
                    </Typography>
                  }
                />
                <FormHelperText
                  error={
                    formik.touched.noShowFee && Boolean(formik.errors.noShowFee)
                  }
                >
                  {formik.touched.noShowFee && formik.errors.noShowFee}
                </FormHelperText>
              </FormControl>
              <TextField
                sx={{ my: 1 }}
                fullWidth
                id="notes"
                name="notes"
                type="text"
                label="Please provide your reason for booking this appointment."
                variant="filled"
                multiline
                rows={3}
                disabled={formik.values.reason === ""}
                value={formik.values.notes}
                onChange={formik.handleChange}
                error={formik.touched.notes && Boolean(formik.errors.notes)}
                helperText={formik.touched.notes && formik.errors.notes}
              />
              {isOnWaitlist && (
                <Alert severity="info" icon={<PendingActionsOutlined />}>
                  <strong>You're already on the waitlist!</strong>
                  <br />
                  You will be automatically removed from the waitlist if you
                  successfully book an appointment.
                </Alert>
              )}
              <CustomButton
                label="Submit"
                onClick={() => {
                  confirmAppointment();
                }}
              ></CustomButton>
            </>
          )}
          {formik.values.reason !== "" &&
            slots.length === 0 &&
            formik.values.date !== "" && (
              <Box
                sx={{
                  pt: 1,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {isOnWaitlist ? (
                  <Alert severity="info" icon={<PendingActionsOutlined />}>
                    <strong> You're already on the waitlist!</strong>
                    <br />
                    All appointment slots are fully booked for the selected
                    date. The clinic will call you once an appointment becomes
                    available.
                  </Alert>
                ) : (
                  <>
                    <Alert severity="error">
                      <strong>
                        All appointment slots are fully booked for the selected
                        date.
                      </strong>
                    </Alert>
                    {formik.values.date === today &&
                      new Date() >= WAITLIST_START_TIME &&
                      new Date() < WAITLIST_END_TIME &&
                      !isDateInHolidayRange(formik.values.date) && (
                        <>
                          <Typography variant="h9" sx={style.typographyBody}>
                            If you would like to be added to our one-day
                            waitlist, please specify your reason for appointment
                            below.
                          </Typography>

                          <TextField
                            sx={{ my: 1 }}
                            fullWidth
                            id="notes"
                            name="notes"
                            type="text"
                            label="Please provide your reason for booking this appointment."
                            variant="filled"
                            multiline
                            rows={3}
                            disabled={formik.values.reason === ""}
                            value={formik.values.notes}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.notes &&
                              Boolean(formik.errors.notes)
                            }
                            helperText={
                              formik.touched.notes && formik.errors.notes
                            }
                          />

                          <CustomButton
                            label="Join Waitlist"
                            disabled={isOnWaitlist}
                            onClick={() => {
                              formik.setFieldValue("available", {
                                waitList: true,
                              });
                              confirmWaitlist();
                            }}
                            sx={{ marginBottom: 3 }}
                          ></CustomButton>
                        </>
                      )}
                  </>
                )}
                <Typography sx={style.typographyBody}>
                  <b> Same Day Waitlist:</b> Waitlist placements are open from 6
                  AM to 6 PM and are valid for today only. If you do not receive
                  a call for an available appointment by the end of the day, we
                  encourage you to try booking your appointment tomorrow for the
                  most up-to-date availability.
                </Typography>
              </Box>
            )}
        </form>
      </Box>
    </>
  );
};

export default BookingForm;
