import Swal from "sweetalert2";
import { confirmButtonColor } from "../../constants/ColourConstants";

const Confirm = (data, message) => {
  if (data.error || data.message) {
    Swal.fire({
      title: data.error ?? data.message,
      text: "Please try again with a different time slot.",
      icon: "error",
      confirmButtonColor: confirmButtonColor,
    });
  } else {
    Swal.fire({
      title: message,
      icon: "success",
      confirmButtonColor: confirmButtonColor,
    });
  }
};

export default Confirm;
