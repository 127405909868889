import { useFormik } from "formik";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
//Endpoints
import {
  bookAppointment,
  sendConfirmation,
  createDynamoAppointment,
} from "../../api/AppointmentEndpointRequests";
import {
  scheduleWaitlist,
  sendWaitlistMessage,
} from "../../api/WaitlistEndpointRequests";
//Components
import Alert from "./Alert";
import handleCancelWaitlist from "../Waitlist/handleCancelWaitlist";
import { combineDateAndTime } from "../Common/FormatTime";

// Validation for front formik.
const validationSchema = Yup.object().shape({
  reason: Yup.string().required("Required"),
  date: Yup.string().required("Required"),
  available: Yup.object().required("Required"),
  notes: Yup.string().required("Required"),
  noShowFee: Yup.boolean().when("available", {
    is: (available) => !available?.waitList,
    then: Yup.boolean().oneOf(
      [true],
      "Please check the box to acknowledge the no-show fee policy."
    ),
  }),
});

const BookingFormik = (state, providers, refreshAppointments, waitlistData) => {
  let navigate = useNavigate();
  return useFormik({
    initialValues: {
      reason: "",
      date: "",
      available: "",
      notes: "",
      noShowFee: false,
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const hasCellPhone = state.demographics.phones.some(
        (item) => item.contactType === "CellPhone"
      );
      const cellPhone = hasCellPhone
        ? `+1${state.demographics.phones
            .filter((item) => item.contactType === "CellPhone")[0]
            ?.["number"].replace(/\D/g, "")}`
        : "";
      if (values.available.waitList) {
        const body = {
          waitlistProviderId: process.env.REACT_APP_ENV_WAITLIST_PROVIDER_ID,
          patientId: state.patientId,
          consultPriority: process.env.REACT_APP_ENV_CONSULT_PRIORITY,
          consultStatus: "Waiting",
          consultType: "Inpatient",
          referralDate: values.date,
          requestDate: values.date,
          notes: `Booked on ${moment().format(
            "YYYY-MM-DD @ HH:mm"
          )} \nPatient Notes: ${values.notes}`,
        };
        const message = {
          name: state.demographics.firstName,
          date: moment(values.date).format("dddd, MMMM Do YYYY"),
          cell: cellPhone,
          reason: values.reason,
        };

        await scheduleWaitlist(body).then((data) => {
          Alert(data, "Waitlist scheduled.");
          if (
            !data.error &&
            data.message !== "Internal server error" &&
            hasCellPhone
          ) {
            sendWaitlistMessage(message);
          }
          navigate("/");
        });
      } else {
        let start = moment(values.available.time, "hh:mm a")
          .format("Hmm")
          .toString();
        let end = moment(start, "Hmm").add(15, "minutes");
        end = moment(end, "H").format("Hmm");
        let body = {
          date: values.date,
          startTime: start,
          endTime: end,
          subColumn: values.available.subColumn,
          patientId: state.patientId,
          providerId: values.available.id,
          resourceId: null,
          officeId: 9392,
          appointmentDetails: {
            billingProviderId: values.available.id,
            referringProviderId: null,
            notes: values.notes,
            reasonId: null,
            typeId: null,
            priorityId: null,
            arrived: false,
            confirmed: false,
            serviceLocation: "O",
          },
        };
        const combinedDateTime = combineDateAndTime(values.date, start);
        let reminderDateTime = moment(combinedDateTime).subtract(
          150,
          "minutes"
        );
        let dateTS =
          reminderDateTime > moment().add(15, "minutes")
            ? reminderDateTime
            : null;
        const message = {
          name: state.demographics.firstName,
          date: moment(values.date).format("dddd, MMMM Do YYYY"),
          startTime: values.available.time,
          reminderAt: dateTS,
          provider:
            start !== "Invalid date"
              ? providers.find(
                  (provider) => provider.id === values.available.id
                ).fullName
              : null,
          cell: cellPhone,
          reason: values.reason,
        };
        if (waitlistData.length) {
          handleCancelWaitlist(
            state.patientId,
            waitlistData[0].id,
            waitlistData[0].referralDate,
            waitlistData[0].requestDate,
            values.date
          );
        }
        await bookAppointment(body).then(async (data) => {
          Alert(data, "Appointment Booked.");
          if (!data.error && !data.message) {
            let messageResponse = null;
            if (hasCellPhone) {
              messageResponse = await sendConfirmation(message);
            }
            const appointment = {
              appointmentId: data,
              patientId: state.patientId,
              date: values.date,
              time: values.available.time,
              phoneNumber: cellPhone,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              confirmationTwilioId: messageResponse?.confirmationSid,
              scheduledTwilioId: messageResponse?.scheduledSid,
              status: "CREATED",
              apptSuggestion: values.reason,
            };
            createDynamoAppointment(appointment);
            navigate("/");
          } else {
            values.available = "";
            refreshAppointments();
          }
        });
      }
    },
  });
};

export default BookingFormik;
