import * as Yup from "yup";
import { provinceLengths } from "../components/PatientRegistration/provinceData";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const validatePhoneNumber = (value) => {
  if (!value) return true;
  try {
    const phoneNumber = parsePhoneNumberFromString(value, "CA");
    return phoneNumber && phoneNumber.isValid();
  } catch {
    return false;
  }
};

const patientValidation = Yup.object().shape({
  firstName: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  lastName: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Required"),
  middleName: Yup.string().max(20, "Must be 20 characters or less"),
  sex: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  postalCode: Yup.string()
    .matches(
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
      "Invalid postal code"
    )
    .required("Required"),
  province: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .test("is-valid-phone", "Invalid phone number format", (value) =>
      validatePhoneNumber(value)
    )
    .test(
      "at-least-one-contact",
      "At least one contact method (phone number or landline) is required.",
      function (value) {
        const { landlineNumber } = this.parent;
        return value || landlineNumber;
      }
    )
    .nullable(),
  landlineNumber: Yup.string()
    .test("is-valid-landline", "Invalid landline number format", (value) =>
      validatePhoneNumber(value)
    )
    .test(
      "at-least-one-contact",
      "At least one contact method (phone number or landline) is required.",
      function (value) {
        const { phoneNumber } = this.parent;
        return value || phoneNumber;
      }
    )
    .nullable(),

  dateOfBirth: Yup.string().required("Required"),
  noHealthCard: Yup.boolean(),
  phin: Yup.string()
    .when("healthCardProvince", {
      is: (province) => !!province && !!provinceLengths[province],
      then: Yup.string()
        .matches(/^[a-zA-Z0-9]+$/, "Must be number or alphanumeric")
        .test(
          "exactLength",
          "Your health card number must be exactly the specified length for the selected province",
          function (value) {
            const province = this.parent.healthCardProvince;
            return value?.length === provinceLengths[province];
          }
        ),
    })
    .required("Required"),

  healthCardProvince: Yup.string().when("noHealthCard", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  regNumber: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, "Must be number or alphanumeric")
    .when("healthCardProvince", {
      is: "MB",
      then: Yup.string().required("Required"),
    }),
  allergies: Yup.string().max(100, "Must be less than 100 characters"),
  patientNotes: Yup.string().max(100, "Must be less than 100 characters"),
});

export default patientValidation;
